import React, { useState } from 'react';
import { Container, Typography, Grid, Box, Button, Modal, Backdrop, Fade } from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Web, Security, Storage, ShoppingCart, PhoneAndroid, Science } from '@mui/icons-material';

const services = [
  {
    title: 'Web Development',
    description: 'Custom, responsive, and high-performing websites that enhance user experience.',
    icon: <Web sx={{ fontSize: '3rem', color: '#90CAF9' }} />,
    details: `
      - Responsive Web Design
      - Performance Optimization
      - SEO Best Practices
      - Custom Backend Development
      - Third-Party API Integration`,
  },
  {
    title: 'Cyber Security',
    description: 'Top-tier cyber security services to protect your business from digital threats.',
    icon: <Security sx={{ fontSize: '3rem', color: '#ff6b6b' }} />,
    details: `
      - Vulnerability Testing
      - Incident Response & Recovery
      - Network Security Solutions
      - Cloud Security Management
      - Data Protection`,
  },
  {
    title: 'Data Management',
    description: 'Reliable services to ensure secure, accessible, and organized data.',
    icon: <Storage sx={{ fontSize: '3rem', color: '#f0a500' }} />,
    details: `
      - Data Migration & Integration
      - Database Management
      - Backup & Disaster Recovery
      - Big Data Solutions
      - Data Analysis & Reporting`,
  },
  {
    title: 'E-Commerce Solutions',
    description: 'Comprehensive e-commerce solutions to help you sell effectively online.',
    icon: <ShoppingCart sx={{ fontSize: '3rem', color: '#66bb6a' }} />,
    details: `
      - Custom Platforms
      - Payment Gateway Integration
      - Shopping Cart Solutions
      - Product Catalog Management
      - Sales & Marketing Tools`,
  },
  {
    title: 'Mobile App Development',
    description: 'Building fast, secure, and intuitive mobile apps for all platforms.',
    icon: <PhoneAndroid sx={{ fontSize: '3rem', color: '#AB47BC' }} />,
    details: `
      - iOS & Android Development
      - Cross-Platform Solutions
      - App Store Optimization
      - UX/UI Design
      - App Maintenance & Updates`,
  },
  {
    title: 'AI and Machine Learning',
    description: 'Empower your business with predictive analytics and automation.',
    icon: <Science sx={{ fontSize: '3rem', color: '#42A5F5' }} />,
    details: `
      - Predictive Analytics
      - Natural Language Processing
      - Machine Learning Models
      - AI Integration
      - Data Science & Deep Learning`,
  },
];

const ServicesPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('');

  const handleOpen = (serviceTitle) => {
    setSelectedService(serviceTitle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedService('');
  };

  return (
    <>
      <Helmet>
        <title>Virginia Beach, Virginia Digital Services | Neal Frazier Tech</title>
        <meta
          name="description"
          content="Explore our wide range of services including web development, software engineering, project management, cyber security, and more."
        />
        <meta
          name="keywords"
          content="web development, software engineering, project management, cyber security, mobile app development, AI, machine learning, e-commerce solutions"
        />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundColor: '#121212',
          paddingTop: '120px',
          paddingBottom: '60px',
          color: '#fff',
        }}
      >
        <ParallaxProvider>
          <Container maxWidth="lg">
            <Parallax speed={10}>
              <Typography
                id="services"
                variant="h2"
                align="center"
                gutterBottom
                sx={{

                  marginTop: '100px',
                  fontSize: '2.5rem',
                  fontWeight: 'bold',
                  background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'white',
                  animation: 'gradientShift 6s infinite',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', // Add shadow for better visibility
                  '@keyframes gradientShift': {
                    '0%': {
                      background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                    },
                    '25%': {
                      background: 'linear-gradient(45deg, #FF5722, #FFC107)',
                    },
                    '50%': {
                      background: 'linear-gradient(45deg, #8BC34A, #4CAF50)',
                    },
                    '75%': {
                      background: 'linear-gradient(45deg, #673AB7, #3F51B5)',
                    },
                    '100%': {
                      background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                    },
                  },
                }}
              >
                Our Services
              </Typography>
            </Parallax>

            <Grid container spacing={4}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        backgroundColor: '#1e1e1e',
                        padding: '30px',
                        borderRadius: '15px',
                        boxShadow: '0 6px 15px rgba(0, 0, 0, 0.4)',
                        minHeight: '600px',
                      }}
                    >
                      {service.icon}
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: '#fff',
                          fontWeight: 'bold',
                          marginTop: '15px',
                        }}
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#ccc',
                          marginBottom: '15px',
                        }}
                      >
                        {service.description}
                      </Typography>
                      <Box
                        sx={{
                          color: '#fff',
                          backgroundColor: '#333',
                          padding: '15px',
                          borderRadius: '8px',
                          fontSize: '0.85rem',
                          lineHeight: 1.6,
                          marginTop: '10px',
                          textAlign: 'left',
                        }}
                      >
                        {service.details.split('\n').map((line, i) => (
                          <Typography key={i} sx={{ marginBottom: '5px' }}>
                            {line.trim()}
                          </Typography>
                        ))}
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => handleOpen(service.title)}
                        sx={{
                          marginTop: '15px',
                          background: 'linear-gradient(45deg, #42A5F5, #90CAF9)',
                          color: '#fff',
                          fontWeight: 'bold',
                          '&:hover': {
                            background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                          },
                        }}
                      >
                        Learn More
                      </Button>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Container>
        </ParallaxProvider>
      </motion.div>

      {/* Modal for Contact Form */}
      <Modal
  open={open}
  onClose={handleClose}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
  sx={{ zIndex: 9999,}}
>
  <Fade in={open}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '80vh', // Limit vertical height to 80% of viewport
        overflowY: 'auto', // Enable scrolling for overflow content
        bgcolor: '#1e1e1e',
        border: '2px solid #90CAF9',
        borderRadius: '15px',
        boxShadow: 24,
        p: 4,
        
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: '#90CAF9',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        {selectedService}: Let's Connect!
      </Typography>
    </Box>
  </Fade>
</Modal>

    </>
  );
};

export default ServicesPage;
