import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { keyframes } from '@emotion/react';

// Define a subtle fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ContactSection = () => {
  return (
    <Box
    id="contact"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
        textAlign: 'center',
        background: 'linear-gradient(135deg, #121212 0%, #1a1a1a 100%)',
        color: '#fff',
        py: 8,
        px: 2,
      }}
    >
      <Container maxWidth="sm">
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            mb: 3,
            animation: `${fadeIn} 0.8s ease-out`,
          }}
        >
          Let's Connect
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 5,
            color: '#b0b0b0',
            animation: `${fadeIn} 1s ease-out`,
          }}
        >
          We’d love to hear about your projects and how we can help. Whether you're a startup or an established business, let’s build something amazing together.
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          sx={{
            animation: `${fadeIn} 1.2s ease-out`,
          }}
        >
          <Button
            variant="contained"
            startIcon={<EmailIcon />}
            href="mailto:business@nealfrazier.tech"
            sx={{
              background: 'linear-gradient(135deg, #1976d2 0%, #115293 100%)',
              color: '#fff',
              py: 1.5,
              fontSize: '1rem',
              '&:hover': {
                background: 'linear-gradient(135deg, #1565c0 0%, #0d47a1 100%)',
              },
            }}
          >
            Email Us
          </Button>

          <Button
            variant="contained"
            startIcon={<PhoneIcon />}
            href="tel:+17572958829"
            sx={{
              background: 'linear-gradient(135deg, #388e3c 0%, #2e7d32 100%)',
              color: '#fff',
              py: 1.5,
              fontSize: '1rem',
              '&:hover': {
                background: 'linear-gradient(135deg, #2e7d32 0%, #1b5e20 100%)',
              },
            }}
          >
            Call Us
          </Button>

          <Button
            variant="contained"
            startIcon={<LinkedInIcon />}
            href="https://www.linkedin.com/in/nealfrazier"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              background: 'linear-gradient(135deg, #0077b5 0%, #005f8b 100%)',
              color: '#fff',
              py: 1.5,
              fontSize: '1rem',
              '&:hover': {
                background: 'linear-gradient(135deg, #005f8b 0%, #004466 100%)',
              },
            }}
          >
            Connect on LinkedIn
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactSection;