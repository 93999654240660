import React from 'react';
import { Helmet } from 'react-helmet-async';
import FancyTicker from '../components/FancyTicker';
import Hero from '../components/Hero';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import StatsSection from '../components/StatsSection';
import ToolsShowcase from '../components/ToolShowcase';
import YouTubeEmbed from '../components/YoutubeEmbed';
import FancyTickerV2 from '../components/FancyTickerV2';
import '../styles/HomePage.css';
import { ParallaxProvider } from 'react-scroll-parallax';

function Home() {
    return (
        <div className="Home-Page">
            <Helmet>
                <title>Virginia Beach Website Developer | Neal Frazier Tech | Modern Website Solutions Architect & Web Application Development</title>
                <meta
                    name="description"
                    content="Neal Frazier Tech specializes in cutting-edge web development, project management, and software engineering services."
                />
                <meta
                    name="keywords"
                    content="Web Application Development, Software Engineering, Project Management, Neal Frazier Tech"
                />
                <meta property="og:title" content="Neal Frazier Tech | Modern Website Solutions Architect & Web Applicationi Development" />
                <meta property="og:description" content="Explore cutting-edge web development and software solutions by Neal Frazier Tech." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://nealfrazier.tech" />
                <meta property="og:image" content="https://nealfrazier.tech/images/newlogo.png" /> {/* Replace with your actual image */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Neal Frazier Tech | Web Application Development & Software Engineering" />
                <meta name="twitter:description" content="Professional web development, project management, and software engineering solutions." />
                <meta name="twitter:image" content="https://nealfrazier.tech/images/newlogo.png" /> {/* Replace with your actual image */}
            </Helmet>

            <ParallaxProvider>
                <Hero />
                <FancyTicker />
                <YouTubeEmbed videoUrl="https://www.youtube.com/embed/JQAX70GE5yQ" />
                <Services />
                <Portfolio />
                <StatsSection />
                <ToolsShowcase />
                <FancyTickerV2 />
            </ParallaxProvider>
        </div>
    );
}

export default Home;
